import React from 'react';
import './Noticias.css';
import { API_URL, axiosconfig } from '../../core';
import axios from 'axios';
import TextTruncate from 'react-text-truncate';
import Animate from 'react-smooth';

class Noticias extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pubs: []
        };
    }

    componentDidMount() {
        let url = API_URL + '/api/pubs?page_size=3&format=json'
        try {
            axios.get(url, axiosconfig).then(res => { this.setState({ pubs: res.data }); });
        } catch (err) {
            console.log(err);
        }
    }

    render() {
        return (
            <Animate to="1" from="0" attributeName="opacity">
            <div  style={{backgroundColor: 'white'}}>
            <div className="container">
                <div className="container-noticias">
                    <span className="blue_delta" style={{fontSize: '1.95rem', fontWeight: 'bold'}}>POR DENTRO DAS NOTÍCIAS DELTA</span>
                    <span className="lightblue" style={{fontSize: '1.5rem'}}>Delta News</span>
                </div>
                <div className="row col-mb-50" style={{ paddingTop: '1rem', zIndex: 3 }}>
                    {this.state.pubs.results ? this.state.pubs.results.map((item, i) =>
                        <div className="col-md-4" key={i}>
                            <div className="feature-box media-box">
                                <div className="fbox-media">
                                    <img src={`${API_URL}${item.image}`} alt="blog" />
                                </div>
                                <div className="fbox-content px-0">
                                    <h3 style={{ color: '#091C8E', fontSize: '1.2rem', fontWeight: 'bold', textTransform: 'none', paddingBottom: '1rem' }}>{item.title}</h3>
                                    <span className="subtitle" >
                                        <TextTruncate
                                            line={2}
                                            element="span"
                                            truncateText="…"
                                            text={item.subtitle}
                                            textTruncateChild={""}
                                        />
                                    </span>
                                    <p >
                                        <TextTruncate
                                            line={4}
                                            element="span"
                                            truncateText="…"
                                            text={item.content}
                                            textTruncateChild={""}
                                        />
                                    </p>
                                </div>
                            </div>
                            <a href={`/pub/${item.slug}`} style={{zIndex: 0 }} >
                                <button className="btn-saiba-mais-blog">SAIBA MAIS</button>
                            </a>
                        </div>
                    ) : <></>}
                </div>
                {/* <Container style={{ display: 'flex', justifyContent: 'center', gridGap: '1rem' }}>
                    {this.state.pubs.results ? this.state.pubs.results.map((item, i) =>
                        <NewsCard news={item} key={i} />
                    ) : null}
                </Container> */}
            </div>
            </div>
            </Animate>
        );
    }
}
export default Noticias;