import Header from "../../components/header/Header";
import Rodape from "../../components/rodape/Rodape";
import bannerSobreimg from '../../images/sobre/banner_sobre_nos.png';
//import parteBannerSobreimg from '../../images/sobre/parte_banner_sobre_nos.png';
import sobre1Img from '../../images/gallery/FOTO-01.jpg';
import sobre2Img from '../../images/gallery/FOTO-02.jpg';
import sobre3Img from '../../images/gallery/FOTO-03.jpg';
import sobre4Img from '../../images/gallery/FOTO-04.jpg';
import sobre5Img from '../../images/gallery/FOTO-05.jpg';
import sobre6Img from '../../images/gallery/FOTO-06.jpg';
import sobre7Img from '../../images/gallery/FOTO-07.jpg';
import sobre8Img from '../../images/gallery/FOTO-08.jpg';
import sobre9Img from '../../images/gallery/FOTO-09.jpg';
import sobre10Img from '../../images/gallery/FOTO-10.jpg';
import sobre11Img from '../../images/gallery/FOTO-11.jpg';
import sobre12Img from '../../images/gallery/FOTO-12.jpg';
import sobre13Img from '../../images/gallery/13.jpeg';
import sobre14Img from '../../images/gallery/14.jpeg';
import postoImg from '../../images/sobre/imagem_posto.png';

import logoImg from '../../images/logo_delta_colorida.png';
import React from 'react';
import axios from 'axios';
import { API_URL, axiosconfig } from '../../core';
import Animate from 'react-smooth';
import './pagesobre.css';
import { Lined } from "../../components/lined/Lined";
import 'react-gallery-carousel/dist/index.css';
import { Carousel } from "react-responsive-carousel";
import video from '../../images/video.mp4';

class Sobre extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            promos: [],
            services: [],
            about: {}, 
            imageIDs:[]
        };
    }

    componentDidMount() {
        let url = API_URL + '/api/promos?format=json'
        try {
            axios.get(url, axiosconfig).then(res => { this.setState({ promos: res.data }); });
        } catch (err) {
            console.error(err);
        }
 
        let url2 = API_URL + '/api/services?format=json'
        try {
            axios.get(url2, axiosconfig).then(res => { this.setState({ services: res.data }); });
        } catch (err) {
            console.error(err);
        }
 
        let url3 = API_URL + '/api/about?format=json'

        try {
            axios.get(url3, axiosconfig).then(res => { this.setState({ about: res.data }); });
        } catch (err) {
            console.error(err);
        }
        
        this.setState({imageIDs: [sobre1Img, sobre13Img, sobre14Img,sobre2Img, sobre3Img, sobre4Img, sobre5Img, sobre6Img, sobre7Img, sobre8Img, sobre9Img, sobre10Img, sobre11Img, sobre12Img]});
    }
    render() {

        return (
            <Animate to="1" from="0" attributeName="opacity">
                <div style={{position: 'relative'}}>
                    <Header alwaysBlue={true} services={this.state.services} promos={this.state.promos} className="delta" />
                    <div id="page-title" style={{ paddingTop: '120px', backgroundImage: `url(${bannerSobreimg})`, height: '700px' }}>
                        <div className="container clearfix">
                            <div className="row">
                                <div className="col-lg-4" style={{ paddingTop: '3rem', paddingBottom: '3rem' }}>
                                    <img src={logoImg} alt="logo delta" style={{ width: '300px' }} />
                                    <span style={{ color: '#666666', fontWeight: 'normal', paddingTop: '1rem' }}>
                                        {this.state.about.intro}
                                    </span>
                                </div>

                                <div className="col-lg-8 alinhaimagembanner" style={{ zIndex: 1, paddingTop: '3rem'}}>
                                        <div>
                                            <Carousel autoPlay style={{maxHeight: '200px'}}>
                                                {this.state.imageIDs.map((img, i) =>
                                                    <div key={i}>
                                                        <img style={{ maxHeight: '400px'}}
                                                            alt="Delta"
                                                            src={img}
                                                        />
                                                    </div> 
                                                )}
                                            </Carousel>
                                        </div>
                                    {/* <img src={parteBannerSobreimg} alt="imagem historia" className="tamanhoimg" /> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{ backgroundImage: 'linear-gradient( #091C8E 80%, #00D3E5 40%)'}} className="about-before-galery"  >
                        <div className="container" >
                            <div className="row" >
                                <div className="col-md-7" >
                                    <img src={postoImg} alt="posto" style={{ width: '100%' }} />
                                </div>
                                <div className="col-lg-5" style={{ color: 'white' }}>
                                    <div className=" border-bottom-0 bottommargin-sm">
                                        <h3 style={{ color: 'white', fontSize: '5rem', fontWeight: 'bold', lineHeight: '0.5' }}>Nossos</h3>
                                        <span style={{ color: '#00D3E5', fontWeight: 'bold', fontSize: '5rem', lineHeight: '0.7' }}>postos</span>
                                    </div>
                                    <p>
                                        {this.state.about.content}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 gallery-custom"  >
                            <div className="col-lg-8">
                            <video autoPlay loop controls
                                src={video} style={{width: '100%', borderBottom: '0px solid #1A76D9' }}>
                                </video>             
                            </div>
                        </div>
                    </div>

                    <Rodape promos={this.state.promos} services={this.state.services} />
                    <Lined/>

                </div>
            </Animate>
        );
    }

}
export default Sobre;
