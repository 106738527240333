import React from 'react';
import './Servicos.css';
import { Container, Row } from 'react-bootstrap';
import ServCard from './card';
import Animate from 'react-smooth';

class Servicos extends React.Component {
    render() {
        return (
            <Animate to="1" from="0" attributeName="opacity">
                <div style={{ backgroundColor: 'white' }}>
                    <p className="blue_delta" style={{ fontSize: '1.95rem', textAlign: 'center', fontWeight: 'bold', paddingTop: '4rem' }}>Conheça os produtos & <span className="color-delta" >serviços delta</span></p>
                    <div style={{ paddingTop: '1rem', paddingBottom: '30px' }}>
                        <Container className="serv-container">
                            <Row className="vert" style={{ gridGap: '15px' }}>
                                {this.props.services.map((item, i) =>
                                    <div key={i}>
                                        <ServCard service={item} />
                                    </div>
                                )}
                            </Row>
                        </Container>
                    </div>
                </div>
            </Animate>
        );
    }
}
export default Servicos;