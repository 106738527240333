import axios from "axios";
import Header from "../../components/header/Header";
import React from 'react';
import { API_URL, axiosconfig } from "../../core";
import bannerImg from '../../images/areasocial/banner.jpg';
import arvoreImg from '../../images/areasocial/arvore.png';
import checkImg from '../../images/areasocial/check.svg';
import './AreaSocial.css';
import Rodape from "../../components/rodape/Rodape";
import { Lined } from "../../components/lined/Lined";

class AreaSocial extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pubs: {},
            page: 1,
            promos: [],
            services: []
        };
    }
    componentDidMount() {
        let url = API_URL + '/api/pubs?format=json'
        try {
            axios.get(url, axiosconfig).then(res => { this.setState({ pubs: res.data }); });
        } catch (err) {
            console.error(err);
        }
        let url2 = API_URL + '/api/promos?format=json'
        try {
            axios.get(url2, axiosconfig).then(res => { this.setState({ promos: res.data }); });
        } catch (err) {
            console.error(err);
        }

        let url3 = API_URL + '/api/services?format=json'
        try {
            axios.get(url3, axiosconfig).then(res => { this.setState({ services: res.data }); });
        } catch (err) {
            console.error(err);
        }
    }
    render() {
        return (
            <>
                <Header alwaysBlue={false} services={this.state.services} promos={this.state.promos} />
                <img src={bannerImg} alt="bannner" className="w-100" />
                <div className="responsabilidade position-relative">
                    <div className="titulo">
                        <h3 className="h3as">RESPONSABILIDADE AMBIENTAL</h3>
                    </div>
                    <div className="container clearfix">
                        <div className="row col-mb-50" style={{ scrollMargin: '100px' }}>
                            <div className="col-md-6" style={{ textAlign: 'right' }}>
                                <img src={arvoreImg} alt="ambientar" className="mb-0" />
                            </div>
                            <div className="col-md-6 description" >

                                <div style={{ marginTop: '3rem', marginLeft: '0', marginRight: '3rem' }}>
                                    {/* <h3 style={{ color: '#091C8E' }}><strong>asdasdas</strong></h3><br /> */}
                                    <span style={{ fontSize: '0.9rem', color: '#fff' }}>Na vida devemos trabalhar a lei da compensação. Se precisamos retirar da natureza para nossa sobrevivência, é nosso dever realizar ações que revertam esse quadro.</span><br /><br />
                                    <span style={{ fontSize: '0.9rem', color: '#fff' }}> Nos postos da Rede Delta buscamos fazer a nossa parte. Conheça algumas ações:  </span><br />
                                    <span style={{ fontSize: '0.9rem', color: '#fff', paddingTop: '1rem' }}>
                                        <img src={checkImg} alt="" style={{ width: '30px', paddingRight: '10px' }} />
                                        Uso de materiais biodegradáveis.
                                    </span><br />
                                    <span style={{ fontSize: '0.9rem', color: '#fff' }}>
                                        <img src={checkImg} alt="" style={{ width: '30px', paddingRight: '10px' }} />Equipe ambiental interna.</span><br />
                                    <span style={{ fontSize: '0.9rem', color: '#fff' }}>
                                        <img src={checkImg} alt="" style={{ width: '30px', paddingRight: '10px' }} />Lixo zero de eletrônicos (reciclagem/reaproveitamento).</span><br />
                                    <span style={{ fontSize: '0.9rem', color: '#fff' }}>
                                        <img src={checkImg} alt="" style={{ width: '30px', paddingRight: '10px' }} />Separação de todo tipo de resíduos para o correto descarte (sem contaminar o meio ambiente). </span><br />
                                    <span style={{ fontSize: '0.9rem', color: '#fff' }}>
                                        <img src={checkImg} alt="" style={{ width: '30px', paddingRight: '10px' }} />Medidor de combustível que detecta qualquer vazamento.</span><br />
                                    <span style={{ fontSize: '0.9rem', color: '#fff' }}>
                                        <img src={checkImg} alt="" style={{ width: '30px', paddingRight: '10px' }} />Ações sustentáveis com colaboradores e clientes. </span><br />
                                    <br />
                                    <p style={{ fontSize: '0.9rem', color: '#fff' }}>Preservar é bom, mas é preciso que todos lutem por esta causa.  Contamos com você!</p>
                                </div>
                                <p style={{ paddingTop: '1rem', whiteSpace: 'pre-wrap' }}></p>
                            </div>
                        </div>
                    </div>
                    <Rodape promos={this.state.promos} services={this.state.services} />
                    <Lined />
                </div>

            </>
        );
    }
}


export default AreaSocial;