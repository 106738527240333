import './Historia.css';
import {useEffect, useState} from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import { API_URL, axiosconfig } from '../../core';
import Animate from 'react-smooth';


export default function Historia(){
    const [about, setAbout] = useState(true);
  
    useEffect(() => {
        let url = API_URL + '/api/about?format=json'
        try {
            axios.get(url, axiosconfig).then(response => { setAbout(response.data) });
        } catch(err) {
            console.error(err);
        }
      }, []);

      return (
            <Animate to="1" from="0" attributeName="opacity">
                <div className="containerhistoria" id="sobre" >
                    <div className="itemhistoria">
                         <img src={`${API_URL}${about.image}`} alt="sobre delta" />
                    </div>
                    <div className="itemhistoria">
                        <div className="col-md-12 mgtop deschistoria" >
                            <span className={'sub'} style={{ zIndex: 3 }}>NOSSA</span>
                            <p><span className={'tit'} style={{ zIndex: 3 }}>HISTÓRIA</span></p>
                        </div>
                        <div className="col-lg-8 deschistoria" style={{ paddingTop: 0, zIndex: 3 }}>
                            <p className="texto">
                                {about.content}
                            </p>
                            <a href="/sobre">
                                <Button className="btnsaibamais">SAIBA MAIS</Button>
                            </a>
                        </div>
                    </div>

                </div>
            </Animate>
        );
}
