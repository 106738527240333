import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import banner from '../../images/logo_padrao_delta.png';
import './Slide.css';
import { API_URL } from '../../core';
import ReactMarkdown from 'react-markdown';
import SmoothImage from '../smoothImage/SmoothImage';
import Animate from 'react-smooth';
//import video from '../../images/video.mp4';

export default class Slide extends React.Component {

  render(){

    return (
    <Animate to="1" from="0" attributeName="opacity">
      <div id="showCarousel" style={{width: "100%"}}>
        <Carousel >          
          {/* <Carousel.Item style={{ backgroundSize: "cover", backgroundRepeat: "no-repeat" }} key={1}>
            <video 
              controls 
              autoPlay 
              src={video} style={{width: '100%', borderBottom: '0px solid #1A76D9' }}>
             </video>
            <Carousel.Caption  className="center">
              <span ><ReactMarkdown>{this.props.slides.title01}</ReactMarkdown></span>
            </Carousel.Caption>
          </Carousel.Item>
          {/*  */}
          <Carousel.Item style={{ backgroundSize: "cover", backgroundRepeat: "no-repeat" }}  key={1}>
            <SmoothImage
              className="d-block height"
              src={this.props.slides.banner02 ? `${API_URL}${this.props.slides.banner01}` : banner}
              alt={this.props.slides.title02}
              transitionTime={1.0}
            />
            <Carousel.Caption className="center">
              <ReactMarkdown>{this.props.slides.title02}</ReactMarkdown>
            </Carousel.Caption>
          </Carousel.Item> 
           <Carousel.Item style={{ backgroundSize: "cover", backgroundRepeat: "no-repeat" }}  key={2}>
            <SmoothImage
              className="d-block height"
              src={this.props.slides.banner02 ? `${API_URL}${this.props.slides.banner02}` : banner}
              alt={this.props.slides.title02}
              transitionTime={1.0}
            />
            <Carousel.Caption className="center">
              <ReactMarkdown>{this.props.slides.title02}</ReactMarkdown>
            </Carousel.Caption>
          </Carousel.Item> 
          <Carousel.Item style={{ backgroundSize: "cover", backgroundRepeat: "no-repeat" }}  key={3}>
            <SmoothImage
              className="d-block height"
              src={this.props.slides.banner03 ? `${API_URL}${this.props.slides.banner03}` : banner}
              alt={this.props.slides.title03}
              transitionTime={1.0}
            />
            <Carousel.Caption className="center">
              <ReactMarkdown>{this.props.slides.title03}</ReactMarkdown>
            </Carousel.Caption>
          </Carousel.Item> 
        </Carousel>
      </div>
      </Animate>
    );
  }
}
//<p>Viagem combina com a <span className="color-delta">Parada Delta</span></p>