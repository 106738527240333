import './Promocoes.css';
import React from 'react';
import { API_URL } from '../../core';
import { Button } from 'react-bootstrap';
import Animate from 'react-smooth';

export default class PromoCard extends React.Component {
    render(){
        return (
            <Animate to="1" from="0" attributeName="opacity">
            <a href={`/promocoes#${this.props.promo.slug}`}>
            <div className="hovering" style={{backgroundImage: `url(${API_URL}${this.props.promo.image})`}}>
                <div className="overlay">
                    <div className="vertical-center">
                            <span style={{fontSize: '1.8rem'}}>{this.props.promo.title}</span>
                            <p style={{marginTop: '10px'}}>
                                <a href={`/promocoes#${this.props.promo.slug}`}><Button>VER</Button></a>
                            </p>
                    </div>                                    
                </div>
            </div>
            </a>
            </Animate>
        );
    }
}
