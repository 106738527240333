import './Promocoes.css';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PromoCard from './card';
import Animate from 'react-smooth';

class Promocoes extends React.Component {
    render() {
        return (
            <Animate to="1" from="0" attributeName="opacity">
            <div style={{backgroundColor: 'white'}}>
                <div className="container promo">
                    <Row style={{ textAlign: 'center' }}>
                        <Col sm className={'nospace'} style={{ zIndex: 9998 }}>
                            <div className="vertical" style={{ maxWidth: '100%' }}>
                                <span className="textpromo" style={{ color: '#00D3E5', textAlign: 'right', lineHeight: '0.7', fontWeight: 'bold' }}>PRO</span><br />
                                <span className="textpromo" style={{ color: '#091C8E', textAlign: 'left', lineHeight: '1', fontWeight: 'bold' }}>MO</span><br />
                                <span className="textpromo" style={{ color: '#52D84B', textAlign: 'right', lineHeight: '1', fontWeight: 'bold' }}>ÇÕES</span>
                            </div>
                        </Col>
                        {this.props.promos.map((item, i) =>
                            <Col sm style={{ paddingRight: '0px', paddingLeft: '0px' }} key={i}>
                                <PromoCard promo={item} />
                            </Col>
                        )}
                    </Row>
                </div>
            </div>
        </Animate>
        );
    }
}
export default Promocoes;