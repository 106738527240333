//const { REACT_APP_TOKEN } = process.env;

export const API_URL = 'https://delta.duocyber.com';
export const axiosconfig = {
    headers: { Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjozMzE1MzkwNjc5NywianRpIjoiMzExMTAxMzRjZjRlNDI3MTgzYTk5OTAwYjIyYzRkNjQiLCJ1c2VyX2lkIjozfQ.CUF13JYkECqrmei2G42PDmnR7P2uYiuTdur8FwQfohk' }
};

export const axiosconfigwithfiles = {
    headers: { Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjozMzE1MzkwNjc5NywianRpIjoiMzExMTAxMzRjZjRlNDI3MTgzYTk5OTAwYjIyYzRkNjQiLCJ1c2VyX2lkIjozfQ.CUF13JYkECqrmei2G42PDmnR7P2uYiuTdur8FwQfohk', 'Content-Type': 'multipart/form-data' }
};

export const GOOGLE_SITE_KEY = '6LcZPMwgAAAAAFXdTzmwYouVB4rxFP_0G4rvIUWz';
