import Header from "../../components/header/Header";
import Rodape from "../../components/rodape/Rodape";
import React from 'react';
import axios from 'axios';
import { API_URL, axiosconfig } from '../../core';
import { Carousel } from "react-responsive-carousel";
import BannerMobServ from "../../components/bannerMob/BannerMobServ";
import Animate from 'react-smooth';

class Produtoseservicos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            promos: [],
            services: [],
            service: {}
        };
    }

    componentDidMount() {
        let url = API_URL + '/api/promos?format=json'
        try {
            axios.get(url, axiosconfig).then(res => { this.setState({ promos: res.data }); });
        } catch (err) {
            console.error(err);
        }
  
        let url2 = API_URL + '/api/services?format=json'
        try {
           axios.get(url2, axiosconfig).then(res => { this.setState({ services: res.data }); });
        } catch (err) {
            console.error(err);
        }
  
        let url3 = API_URL + `/api/service?slug=${this.props.match.params.slug}&format=json`
        try {
            axios.get(url3, axiosconfig).then(response => this.setState({ service: response.data }));
        } catch (err) {
            console.error(err);
        }
    }
    render() {
        return (
            <Animate to="1" from="0" attributeName="opacity">
            <div>
                <Header alwaysBlue={true} promos={this.state.promos} services={this.state.services}/>
                    <BannerMobServ banner={this.state.service.banner} />
                    <section style={{paddingBottom: '3rem'}} >
                            <div className="container clearfix">
                                <div className="row" style={{paddingTop: '3rem'}}>
                                    <br/><br/>
                                    <div className="col-lg-12" style={{textAlign: 'center', paddingBottom: '3rem'}}>
                                        <h2 style={{ color: '#091C8E', fontWeight: 'bold' }}>{this.state.service.title}</h2>
                                    </div>
                                    <div className="col-lg-6">
                                        <div>
                                            <Carousel autoPlay>
                                                {this.state.service.image01 !== '/uploads/404.png'?
                                                <div>
                                                    <img
                                                        alt="Delta"
                                                        src={`${API_URL}${this.state.service.image01}`}
                                                    />
                                                </div> 
                                                : <></>}
                                                {this.state.service.image02 !== '/uploads/404.png' ?
                                                <div>
                                                    <img
                                                        alt="Delta"
                                                        src={`${API_URL}${this.state.service.image02}`}
                                                    />
                                                </div> 
                                                : <></>}
                                                {this.state.service.image03 !== '/uploads/404.png'?
                                                <div>
                                                    <img
                                                        alt="Delta"
                                                        src={`${API_URL}${this.state.service.image03}`}
                                                    />
                                                </div> 
                                                : <></>}
                                            </Carousel>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="col-lg-12">
                                            <span style={{whiteSpace: 'pre-wrap'}}>
                                                {this.state.service.content}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </section>
                <Rodape promos={this.state.promos} services={this.state.services} />
            </div >
            </Animate>
        );
    }
}
export default Produtoseservicos;