import Header from "../../components/header/Header";
import Rodape from "../../components/rodape/Rodape";
import React from 'react';
import axios from 'axios';
import { API_URL, axiosconfig } from '../../core';
import PromoServCard from "./card";
import Animate from 'react-smooth';

class PromocoesDelta extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            promos: [],
            services: [],
            layout: {}
        };
    }

    componentDidMount() {
        let url = API_URL + '/api/promos?format=json'
        try {
             axios.get(url, axiosconfig).then(res => { this.setState({ promos: res.data }) });
        } catch (err) {
            console.error(err);
        }
      
        let url2 = API_URL + '/api/services?format=json'
        try {
            axios.get(url2, axiosconfig).then(res => { this.setState({ services: res.data }) });
        } catch (err) {
            console.error(err);
        }

        let url3 = API_URL + '/api/layout?format=json'
        try {
            axios.get(url3, axiosconfig).then(res => { this.setState({ layout: res.data }) });
        }catch (err) {
            console.error(err);
        }
    }
    render() {
        return (
            <Animate to="1" from="0" attributeName="opacity">
                <div>
                    <div className="stretched fundo">
                        <Header alwaysBlue={true} services={this.state.services} promos={this.state.promos} />
                        <div className="clearfix">
                            <section id="page-title" style={{ backgroundImage: `url(${API_URL}${this.state.layout.bannerPromo}) `, height: '550px' }}>
                                <div className="container clearfix" style={{ top: '45%' }}>
                                    <h1 style={{ color: '#ffea00', fontSize: '3rem' }}>Promoções Delta</h1>
                                    <span style={{ color: '#ffffff', fontWeight: 'bold', fontSize: '1.5rem' }}>Verifique as promoções que a rede Delta tem para você!</span>
                                </div>
                            </section>
                            <div className="section border-top-0  mb-20 p-0 bg-transparent" >
                                <div className="container clearfix">
                                    {this.state.promos.map((item, i) =>
                                        <PromoServCard promo={item} n={i} />
                                    )}
                                </div>
                            </div>
                            <Rodape promos={this.state.promos} services={this.state.services} />
                        </div>
                    </div>
                </div >
            </Animate>
        )
    };
}
export default PromocoesDelta;