import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import React from "react";
import Animate from 'react-smooth';

const isValidCoordinates = require('is-valid-coordinates')

class Mapa extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      lat: "-26.0737735",
      long: "-53.0585274",
      zoom: 4.5
    };
  }
   
  render(){
    return (      
      <Animate to="1" from="0" attributeName="opacity">
      <div style={{width: '100%'}}>
        <MapContainer center={[this.state.lat, this.state.long]} zoom={this.state.zoom} scrollWheelZoom={true} animate={true}>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {this.props.fuelStations.map((item, i) => (isValidCoordinates(parseFloat(item.longitude), parseFloat(item.latitude)) ?
        <Marker position={[item.latitude, item.longitude]}>
          <Popup>
            <b>Posto Delta</b><br/><br/>
            {item.address} - {item.city} - {item.province}<br/><br/>
            <a href={`tel:${item.phone}`}>{item.phone}</a><br/><br/>
            <a href={`https://www.waze.com/ul?ll=${item.latitude}%2C${item.longitude}&navigate=yes&zoom=17`} target={'_blank'}>Ver no Waze</a>
          </Popup>
        </Marker> : <></>)
        )}
      </MapContainer>
    </div>
    </Animate>
    );
  }
}
  
export default Mapa;
