import './lined.css';
import lines from '../../images/lines.svg';
import Animate from 'react-smooth';

export function Lined() {
    return (
            <Animate to="1" from="0" attributeName="opacity">
                <img src={lines} alt={'delta'} className={'lined'} />
            </Animate>
    );
}