import axios from "axios";
import { Button, Col, Form, Row } from "react-bootstrap";
import Header from "../../components/header/Header";
import { API_URL, axiosconfig } from "../../core";
import './Localizador.css';
import localizador from '../../images/img-localizador.png';
import banner from '../../images/banner-localizador.png';
import Mapa from "./mapa";
import React from "react";
import Rodape from "../../components/rodape/Rodape";
import LocalizadorCard from "./card";
import Carousel, { consts } from 'react-elastic-carousel';
import Animate from 'react-smooth';
import { Lined } from "../../components/lined/Lined";

class Localizador extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            promos: [],
            services: [],
            fuelStations: [],
            provincesAndCities: [],
            provinces: [],
            selectedProvince: 'ESTADO',
            selectedCity: 'CIDADE'
        };
        this.breakPoints = [
            { width: 1, itemsToShow: 1 },
            { width: 550, itemsToShow: 2, itemsToScroll: 2, pagination: false },
            { width: 850, itemsToShow: 5 }
        ]
    }
    componentDidMount() {
        let url = API_URL + '/api/fuelStations?format=json'
        try {
            axios.get(url, axiosconfig).then(res => { this.setState({ fuelStations: res.data }); });
        } catch (err) {
            console.error(err);
        }
     
        let url2 = API_URL + '/api/provincesAndCities?format=json'
        try {
            axios.get(url2, axiosconfig).then(res => { this.setState({ provincesAndCities: res.data }); });
        } catch (err) {
            console.error(err);
        }
      
        let url3 = API_URL + '/api/promos?format=json'
        try {
          axios.get(url3, axiosconfig).then(res => { this.setState({ promos: res.data }); });
        } catch (err) {
            console.error(err);
        }
        
        let url4 = API_URL + '/api/services?format=json'
        try {
            axios.get(url4, axiosconfig).then(res => { this.setState({ services: res.data }); });
        } catch (err) {
            console.error(err);
        }
        if (this.props.location.state.city && this.props.location.state.province) {
            this.setState({ selectedCity: this.props.location.state.city, selectedProvince: this.props.location.state.province })
        }
    }
    getProvinces() {
        let provinces = []
        this.state.provincesAndCities.forEach(function (item) {
            provinces.push(item.province)
        })
        return Array.from(new Set(provinces));
    }

    setProvince(prov) {
        this.setState({ selectedProvince: prov, selectedCity: 'CIDADE' });
    }

    setCity(cit) {
        this.setState({ selectedCity: cit });
    }

    getCities() {
        return this.state.provincesAndCities.filter((item, index) => item.province === this.state.selectedProvince);
    }

    getFuelStations() {
        let filtered = [];
        let selectedProvince = this.state.selectedProvince;
        let fuelStations = this.state.fuelStations;
        this.state.fuelStations.forEach(function (item){
            if (selectedProvince !== 'ESTADO'){
                if (item.province === selectedProvince){
                    filtered.push(item);
                }
            }else{
                filtered = fuelStations;
            }
        });
        let finalList = [];
        let selectedCity = this.state.selectedCity;
        filtered.forEach(function (item){
            if (selectedCity !== 'CIDADE'){
                if (item.city === selectedCity){
                    finalList.push(item);
                }
            }else{
                finalList = filtered;
            }
        })
        return finalList;
    }

    myArrow({ type, onClick, isEdge }) {
        const pointer = type === consts.PREV ? '<' : '>'
        return (
            <Button variant="outline-primary" onClick={onClick}>
                {pointer}
            </Button>
        )
    }

    render() {
        return (            
            <Animate to="1" from="0" attributeName="opacity">
            <div id="top" style={{position: 'relative'}}>
                <Header services={this.state.services} promos={this.state.promos}/>
                <div style={{ height: '60vh', position: "relative", justifyContent: 'center', display: 'flex', alignItems: 'center', backgroundImage: `url(${banner})`  }} >
                    <div className="container" style={{ textAlign: 'center' }}>
                        <img src={localizador} alt="localizador" style={{ width: '5rem', maxWidth: '10%' }} />
                        <h4 style={{ textAlign: 'center', color: '#091C8E', fontWeight: 'bold', padding: '1rem' }}>LOCALIZADOR DELTA</h4>
                    </div>
                    <div className="container" style={{ position: 'absolute', bottom: 8 }}>
                        <Row style={{ minHeight: '100px', alignItems: 'center', backgroundColor: '#FFF', borderRadius: '0.5rem', padding: '1rem', gridGap: '5px' }}>
                            <Col className="contmob" style={{paddingTop: '1rem'}}><h3 style={{fontSize: '1.5rem', paddingLeft: '5rem', textAlign: 'center'}}>ENCONTRE UM <strong style={{color: '#00D3E5'}}>POSTO DELTA</strong><br></br> MAIS PRÓXIMO DE VOCÊ!</h3></Col>
                            <Col md="auto">
                                <div>
                                    <Form.Control as="select" onChange={(event) => { this.setProvince(event.target.value) }}>     
                                        <option>ESTADO</option>                                   
                                        {this.getProvinces().map((item, i) =>
                                            <option>{item}</option>
                                        )}
                                    </Form.Control>
                                </div>
                            </Col>
                            <Col>
                                <Form.Control as="select" onChange={(event) => { this.setCity(event.target.value) }}>
                                    <option>CIDADE</option>
                                    {this.getCities().map((item, i) =>
                                        <option>{item.city}</option>
                                    )}
                                </Form.Control>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="container carou" style={{ width: '100%', height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {this.getFuelStations().length > 0 ?
                        <Carousel breakPoints={this.breakPoints} itemPadding={[0, 110]} itemsToScroll={1} itemsToShow={5} renderArrow={this.myArrow} showEmptySlots renderPagination={({ pages, activePage, onClick }) => { return (<div></div>) }} >
                            {this.getFuelStations().map((item, i) =>
                                <LocalizadorCard fuelStation={item} />
                            )}
                        </Carousel> : <></>}
                </div>
                <div className="container" style={{paddingBottom: '3rem'}}>
                <Mapa fuelStations={this.state.fuelStations} />
                </div>
                <Rodape promos={this.state.promos} services={this.state.services} />
                <Lined />
            </div>
        </Animate>
        );
    }
}
export default Localizador;