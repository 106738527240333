import React from 'react';
import { API_URL } from '../../core';
import Animate from 'react-smooth';
import { Form } from 'react-bootstrap';

import './cardpromocao.css';

export default class PromoServCard extends React.Component {
    
    render() {

        const regex = /[0-9]/;

        return (            
            <Animate to="1" from="0" attributeName="opacity">
                <>
                    <hr />
                    <br />
                    <div className="row col-mb-50" id={this.props.promo.slug} style={{scrollMargin: '100px'}}>
                        <div className="col-md-6">
                            <img src={`${API_URL}${this.props.promo.imageDetails}`} alt={this.props.promo.title} className="mb-0" style={{ width: '600px' }} />
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-lg-8">
                                <h3 style={{color: '#091C8E'}}><strong>{this.props.promo.title}</strong></h3><br/>
                                    <span style={{fontSize: '1.2rem'}}>{this.props.promo.subtitle}</span><br/>
                                    <span> Promoção válida até {this.props.promo.validity}.</span>
                                </div>
                                <div className="col-lg-4 valor" style={{alignItems: 'center', display: 'flex'}}>
                                    <span className="valor"><small style={{fontWeight: 'bold', fontSize: '1.8rem'}}>{regex.test(this.props.promo.price) ? `R$` : '' } </small>{this.props.promo.price}</span>
                                </div>
                            </div>
                            <hr/>
                            <p style={{paddingTop: '1rem', whiteSpace: 'pre-wrap'}}>{this.props.promo.content}</p>
                            <div className="" style={{ paddingTop: '5px' }}>
                                <span>
                                    Atenção, a promoção é <span>válida</span> apenas para os postos listados abaixo!
                                </span>
                                <br />
                                <br />
                                    <div>
                                         <Form.Control as="select" >
                                         <option>CLIQUE PARA SABER OS POSTOS</option> 
                                         {this.props.promo.fuelStations.map((item, i) =>
                                            <option>{item.city}</option> 
                                            )}
                                        </Form.Control>
                                    </div>
                            </div>
                        </div>
                    </div>
                </>
            </Animate>
        );
    }
}