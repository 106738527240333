import './BannerMob.css';
import React from 'react';
import { API_URL } from '../../core';
import ReactMarkdown from 'react-markdown';
import Animate from 'react-smooth';
class BannerMob extends React.Component {
    render(){
        return (
            <Animate to="1" from="0" attributeName="opacity">
            <div className="bannerMob">
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', float: 'left', backgroundImage: `url(${API_URL}${this.props.banner})`}} className={'bannercrop'}>
                    <span style={{fontSize: '2rem', textAlign: 'center', color: '#fff'}}><ReactMarkdown>{this.props.title}</ReactMarkdown></span>
                </div>                
            </div>            
            </Animate>
        );
    }
}
export default BannerMob;