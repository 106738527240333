import './TrabalheConosco.css';
import './font-icons.css';
import Header from '../../components/header/Header';
import React from 'react';
import axios from 'axios';
import { API_URL, axiosconfig} from '../../core';
import Rodape from '../../components/rodape/Rodape';
import Animate from 'react-smooth';
import banner from '../../images/banner-vagas.jpg';
import { Lined } from '../../components/lined/Lined';
import ReCAPTCHA from "react-google-recaptcha";
import { Button, Col, Form} from 'react-bootstrap';

const recaptchaRef = React.createRef();

class TrabalheConosco extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            promos: [],
            services: [],
            name: '',
            phone: '',
            email: '',
            message: '',
            curr: {},
            sent: false,
            err: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleSubmit(e) {
        e.preventDefault();
        const recaptchaValue = recaptchaRef.current.getValue();
        const data = new FormData()
        data.append('name', this.state.name);
        data.append('phone', this.state.phone);
        data.append('email', this.state.email);        
        data.append('message', this.state.message);
        data.append('curr', this.state.curr);
        data.append('grecaptcha', recaptchaValue);
        axios.post(`${API_URL}/api/jobs`, data, axiosconfig)
            .then(res => {
                if (res.status === 200) {
                    this.setState({ sent: true, err: false })
                }
            }).catch(err =>
                this.setState({ err: true })
            )
        return false;
    }
    handleFileInput(e){
        this.setState({curr : e.target.files[0]});
    }
    componentDidMount() {       
        let url2 = API_URL + '/api/promos?format=json'
        try {
            axios.get(url2, axiosconfig).then(res => { this.setState({ promos: res.data }); });
        } catch (err) {
            console.error(err);
        }

        let url3 = API_URL + '/api/services?format=json'
        try {
            axios.get(url3, axiosconfig).then(res => { this.setState({ services: res.data }); });
        } catch (err) {
            console.error(err);
        }
    }
    render() {
        return (            
            <Animate to="1" from="0" attributeName="opacity">
            <div style={{position: 'relative'}}>
                <Header alwaysBlue={true} services={this.state.services} promos={this.state.promos} />
                <body className="stretched">
                    <div className="clearfix">
                        <section id="page-title" style={{ backgroundImage: `url(${banner})`, paddingTop: '300px' }}>
                            <div className="container clearfix" >
                                <h1 style={{ color: '#ffffff' }}>Trabalhe conosco</h1>
                                <span style={{ color: '#ffffff', fontWeight: 'bold' }}>Registre o seu currículo</span>
                            </div>
                        </section>
                        <section id="content">
                            <div className="content-wrap">
                                <div className="container clearfix">
                                    <div className="row col-mb-80">
                                          {!this.state.sent ?
                                            <Form style={{ marginBottom: '30px' }} onSubmit={this.handleSubmit}>
                                                <Form.Row>
                                                    <Form.Group as={Col} controlId="formGridEmail">
                                                        <Form.Control type="text" onChange={(e) => this.setState({ name: e.target.value })} placeholder="* Nome" className="input" required />
                                                    </Form.Group>

                                                    <Form.Group as={Col} controlId="formGridPassword">
                                                        <Form.Control type="text" onChange={(e) => this.setState({ phone: e.target.value })} placeholder="* Telefone" className="input" required />
                                                    </Form.Group>
                                                </Form.Row>

                                                <Form.Group controlId="formGridAddress1">
                                                    <Form.Control type="email" onChange={(e) => this.setState({ email: e.target.value })} placeholder="* E-mail" className="input" required />
                                                </Form.Group>

                                                <Form.Group controlId="formGridAddress2">
                                                    <Form.Control as="textarea" onChange={(e) => this.setState({ message: e.target.value })} placeholder="* Mensagem" className="input" required rows="4" />
                                                </Form.Group>
                                                <Form.Group controlId="formFile">
                                                    <Form.Label>Selecione o seu currículo</Form.Label>
                                                    <Form.Control type="file" onChange={(e) => this.handleFileInput(e)} />
                                                </Form.Group>
                                                <Form.Group>
                                                    <ReCAPTCHA
                                                        ref={recaptchaRef}
                                                        sitekey="6LcZPMwgAAAAAFXdTzmwYouVB4rxFP_0G4rvIUWz"
                                                    />
                                                </Form.Group>

                                                <Button variant="primary" type="submit" style={{ backgroundColor: '#00D3E5' }}>
                                                    ENVIAR
                                                </Button>
                                                <span style={{ color: 'white' }}> *Campos com preenchimento obrigatório.</span>
                                                {this.state.sent ? <span>Enviado!</span> : null}
                                            </Form> :
                                            <div style={{ textAlign: 'center' }}><h3 style={{ color: "green" }}>Obrigado pelo seu contato!</h3></div>
                                        }
                                        {this.state.err ? <div style={{ textAlign: 'center' }}><h4 style={{ color: "red" }}>Verifique a sua conexão e tente novamente</h4></div> : null}
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </body>
                <Rodape promos={this.state.promos} services={this.state.services} />
                <Lined />
            </div>
            </Animate>
        );
    }
}

export default TrabalheConosco;
