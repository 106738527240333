import Slide from '../../components/carousel/Slide';
import localizador from '../../images/img-localizador.png';
import { Col, Row, Form } from 'react-bootstrap';
import Rodape from '../../components/rodape/Rodape';
import Header from '../../components/header/Header';
import Promocoes from '../../components/promocoes/Promocoes';
import Servicos from '../../components/servicos/Servicos';
import Noticias from '../../components/noticias/Noticias';
import Historia from '../../components/historia/Historia';
import { Lined } from '../../components/lined/Lined';
import React from 'react';
import axios from 'axios';
import { API_URL, axiosconfig } from '../../core';
import BannerMob from '../../components/carousel/BannerMob';
import './Home.css';
import Animate from 'react-smooth';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      promos: [],
      services: [],
      slides: {},
      provincesAndCities: [],
      provinces: [],
      selectedProvince: 'SC'
    };
  }

  componentDidMount() {
    let url = API_URL + '/api/promos?format=json'
    try {
      axios.get(url, axiosconfig).then(res => { this.setState({ promos: res.data }); });
    } catch (err) {
      console.error(err);
    }
    
    let url2 = API_URL + '/api/services?format=json'
    try {
      axios.get(url2, axiosconfig).then(res => { this.setState({ services: res.data }); });
    } catch (err) {
      console.error(err);
    }
    
    let url3 = API_URL + '/api/provincesAndCities?format=json'
    try {
      axios.get(url3, axiosconfig).then(res => { this.setState({ provincesAndCities: res.data }); });
    } catch (err) {
      console.error(err);
    }
   
    let url4 = API_URL + '/api/layout?format=json'

    try {
      axios.get(url4, axiosconfig).then(res => { this.setState({ slides: res.data }); });
    } catch (err) {
      console.error(err);
    }
  }

  getProvinces() {
    let provinces = []
    this.state.provincesAndCities.forEach(function (item) {
      provinces.push(item.province)
    })
    return Array.from(new Set(provinces));
    //return provinces.filter((item, index) => provinces.indexOf(item) === index);
  }

  setProvince(prov) {
    this.setState({ selectedProvince: prov });
  }

  getCities() {
    return this.state.provincesAndCities.filter((item, index) => item.province === this.state.selectedProvince);
  }

  render() {
    return (
      <Animate to="1" from="0" attributeName="opacity">
        <div style={{ position: 'relative' }}>
          <Header services={this.state.services} promos={this.state.promos} />
          <BannerMob banner={this.state.slides.banner01} title={this.state.slides.title01} />
          <div className="slide-app">
            <Slide slides={this.state.slides} />
            <div className="slide-inner container">
              <Row style={{ alignItems: 'center', textAlign: 'center' }}>
                <Col xs={2} className="img-localizador localizamob" >
                  <Animate to="1" from="0" attributeName="opacity">
                    <img src={localizador} alt="localizador" />
                  </Animate>
                </Col>
                <div className="img-loc-mob">
                  <img src={localizador} alt="localizador" style={{ width: '50px' }} />
                </div>
                <Col md="4" ><h3 className="font_card_localizador" style={{ marginTop: '0.5rem' }}>ENCONTRE UM <strong>POSTO DELTA</strong><br></br> MAIS PRÓXIMO DE VOCÊ!</h3></Col>
                <Col sm={1} className="select-estado">
                  <Form.Control as="select" onChange={(event) => { this.setProvince(event.target.value) }}>
                    {this.getProvinces().map((item, i) =>
                      <option>{item}</option>
                    )}
                  </Form.Control>
                </Col>
                <Col sm={3} style={{ padding: '1rem' }}>
                  <Form.Control as="select" onChange={(event) => { this.props.history.push({ pathname: '/localizador', state: { city: event.target.value, province: this.state.selectedProvince } }) }} >
                    <option>SELECIONE A CIDADE</option>
                    {this.getCities().map((item, i) =>
                      <option>{item.city}</option>
                    )}
                  </Form.Control>
                </Col>
              </Row>
            </div>
          </div>
          <Promocoes promos={this.state.promos} />
          <Servicos services={this.state.services} />
          <Noticias />
          <div style={{ marginTop: '50px' }}>
            <Historia />
          </div>
          <Rodape promos={this.state.promos} services={this.state.services} />
          <Lined />
        </div>
      </Animate>
    );
  }
}

export default Home;
