import React from 'react';
import TextTruncate from 'react-text-truncate';
import { API_URL } from '../../core';
import Animate from 'react-smooth';

export default class PostCard extends React.Component {
    formatterDate(dt) {
        return dt ? Intl.DateTimeFormat('pt-BR').format(new Date(dt)) : '';
    }
    render() {
        return (            
            <Animate to="1" from="0" attributeName="opacity">
            <div className="entry col-12">
                <div className="grid-inner row no-gutters">
                    <div className="entry-image col-md-4">
                        <img src={`${API_URL}${this.props.pub.image}`} alt={this.props.pub.title} />
                    </div>
                    <div className="col-md-8 pl-md-4">
                        <div className="entry-title title-sm">
                            <h2><a href={`/pub/${this.props.pub.slug}`}>{this.props.pub.title}</a></h2>
                        </div>
                        <div className="entry-meta">
                            <ul>
                                <li><i className="icon-calendar3"></i> {this.formatterDate(this.props.pub.pub)}</li>
                            </ul>
                        </div>
                        <div className="entry-content">
                            <p>
                                <TextTruncate
                                    line={3}
                                    element="span"
                                    truncateText="…"
                                    text={this.props.pub.content}
                                    textTruncateChild={''}
                                />
                            </p>
                            <a href={`/pub/${this.props.pub.slug}`} className="btn-saiba-mais-blog" style={{fontWeight: 'normal'}}><b>Ler mais</b></a>
                        </div>
                    </div>
                </div>
            </div>
            </Animate>
        );
    }
}