import './Servicos.css';
import React from 'react';
import { API_URL } from '../../core';
import Animate from 'react-smooth';

class ServCard extends React.Component {
    render(){
        return (
            <Animate to="1" from="0" attributeName="opacity">
            <a href={`/servicos/${this.props.service.slug}`}> 
                <img src={`${API_URL}${this.props.service.icon}`} alt="" style={{paddingRight: '1rem', paddingTop: '2rem'}} />                
            </a>
            </Animate>
        );
    }
}
export default ServCard;