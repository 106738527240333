import logodelta from '../../images/delta.svg';
import React from 'react';
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import Hamburger from 'hamburger-react';
import "./header.css";
import Animate from 'react-smooth';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bg: this.props.alwaysBlue ? 'delta' : 'transparent',
            bottomborder: this.props.alwaysBlue ? '' : 'bottomborder'
        };
    }

    listenScrollEvent = e => {
        if (!this.props.alwaysBlue) {
            if (window.scrollY > 50) {
                this.setState({ bg: "delta", bottomborder: '' });
            } else {
                this.setState({ bg: "transparent", bottomborder: 'bottomborder' });
            }
        }
    };

    componentDidMount() {
        window.addEventListener("scroll", this.listenScrollEvent);
    }

    toggle() {
        if (this.state.bg !== 'delta') {
            this.setState({ bg: "delta", bottomborder: '' });
        }
    }
    render() {
        return (
            <Animate to="1" from="0" attributeName="opacity">
                <Navbar onToggle={() => this.toggle()} collapseOnSelect expand="lg" bg={this.state.bg} variant="light" fixed="top" style={{ zIndex: 9999 }}>
                    <Container className={this.state.bottomborder}>
                        <a href="/">
                            <img src={logodelta} alt={'Delta'} style={{ width: '120px', paddingBottom: '10px' }} />
                        </a>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{ border: '1px #fff solid' }}><Hamburger color="#fff" direction="right" /></Navbar.Toggle>
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="mr-auto cNav" style={{ width: '80%', display: 'flex', justifyContent: 'center' }}>
                                <NavDropdown title="Promoções" id="collasible-nav-dropdown">
                                    {this.props.promos.map((item, i) =>
                                        <NavDropdown.Item href={`/promocoes#${item.slug}`}>{item.title}</NavDropdown.Item>
                                    )}
                                </NavDropdown>
                                <NavDropdown title="Produtos e Serviços" id="collasible-nav-dropdown">
                                    {this.props.services.map((item, i) =>
                                        <NavDropdown.Item href={`/servicos/${item.slug}`}>{item.title}</NavDropdown.Item>
                                    )}
                                </NavDropdown>
                                <Nav.Link href="/blog">Blog</Nav.Link>
                                <NavDropdown title="Sobre Nós" id="collasible-nav-dropdown">
                                    <NavDropdown.Item href="/sobre">Sobre Nós</NavDropdown.Item>
                                    <NavDropdown.Item href="/areasocial">Área Social</NavDropdown.Item>
                                </NavDropdown>
                                <Nav.Link href="/#contato">Contato</Nav.Link>
                                <Nav.Link href="/trabalheconosco">Trabalhe Conosco</Nav.Link>
                            </Nav>
                            <Nav>
                                <span>

                                    {/* eslint-disable-line */}<a href={"javascript:trocarIdioma('pt')"}>
                                        <Navbar.Text>
                                            <div className={'btn-circle pt'}><span class="notranslate">PT</span></div>
                                        </Navbar.Text>
                                    </a>
                                    {/* eslint-disable-line */}<a href={"javascript:trocarIdioma('en')"}>
                                        <Navbar.Text>
                                            <div className={'btn-circle en'}><span class="notranslate">EN</span></div>
                                        </Navbar.Text>
                                    </a>
                                </span>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </Animate>
        );
    }

};

export default Header;
