import { BrowserRouter, Switch, Route } from 'react-router-dom';
import './styles/global.css';
import Home from './pages/home/Home';
import Blank from './pages/blank/Blank';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Localizador from './pages/localizador/Localizador';
import Produtoseservicos from './pages/produtoseservicos/Produtoseservicos';
import PromocoesDelta from './pages/promocoesdelta/Promocoesdelta';
import TrabalheConosco from './pages/trabalhe/TrabalheConosco';
import Sobre from './pages/sobre/Sobre';
import Blog from './pages/blog/Blog';
import Pub from './pages/pub/Pub';
import AreaSocial from './pages/areasocial/AreaSocial';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route component={Blank} path={'/blank'} />
        <Route component={Sobre} path={'/sobre'} />
        <Route component={Blog} path={'/blog'} />
        <Route component={Pub} path={'/pub/:slug'} />
        <Route component={Produtoseservicos} path={'/servicos/:slug'} />
        <Route component={PromocoesDelta} path={'/promocoes'} />
        <Route component={Localizador} path={'/localizador'} />
        <Route component={AreaSocial} path={'/areasocial'} />
        <Route component={TrabalheConosco} path={'/trabalheconosco'} />
        {/* Home deve ficar no final da fila sempre */}
        <Route component={Home} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
