import './Blog.css';
import './font-icons.css';
import Header from '../../components/header/Header';
import React from 'react';
import axios from 'axios';
import { API_URL, axiosconfig } from '../../core';
import Animate from 'react-smooth';
import banner from '../../images/banner-blog.png';
import Rodape from '../../components/rodape/Rodape';
import { Lined } from '../../components/lined/Lined';

class Pub extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pub: {},
            promos: [],
            services: []
        };
    }
    componentDidMount() {
        let url = API_URL + `/api/pub?slug=${this.props.match.params.slug}&format=json`
        try {
            axios.get(url, axiosconfig).then(res => { this.setState({ pub: res.data }); });
        } catch (err) {
            console.error(err);
        }
        let url2 = API_URL + '/api/promos?format=json'
        try {
            axios.get(url2, axiosconfig).then(res => { this.setState({ promos: res.data }); });
        } catch (err) {
            console.error(err);
        }
 
        let url3 = API_URL + '/api/services?format=json'
        try { 
            axios.get(url3, axiosconfig).then(res => { this.setState({ services: res.data }); });
        } catch (err) {
            console.error(err);
        }
    }
    formatterDate(dt) {
        return dt ? Intl.DateTimeFormat('pt-BR').format(new Date(dt)) : '';
    }
    render() {
        return (
            <Animate to="1" from="0" attributeName="opacity">
                <div style={{ position: 'relative' }}>
                    <Header alwaysBlue={true} services={this.state.services} promos={this.state.promos} />
                    <body className="stretched">
                        <div className="clearfix">
                            <section id="page-title" style={{ backgroundImage: `url(${banner})`, paddingTop: '300px' }}>
                                <div className="container clearfix" >
                                    <h1 style={{ color: '#ffffff' }}>Blog Delta</h1>
                                </div>
                            </section>
                            <section id="content">
                                <div className="content-wrap">
                                    <div className="container clearfix">
                                        <div style={{ textAlign: 'center' }}>
                                            <span>{this.formatterDate(this.state.pub.pub)}</span>
                                            <h2>{this.state.pub.title}</h2>
                                            <h6 style={{ color: 'grey' }}>{this.state.pub.subtitle}</h6>
                                            <br />
                                            <img src={`${API_URL}${this.state.pub.image}`} alt={this.state.pub.title} style={{ maxWidth: '80%' }} />
                                        </div>
                                        <br />
                                        <span style={{ whiteSpace: 'pre-wrap' }} >{this.state.pub.content}</span>

                                        {this.state.pub.vid && this.state.pub.vid !== '' ?
                                        <div className="col-lg-12" style={{ textAlign: 'center' }}>
                                            <br />
                                            <iframe width="400" height="400" src={`https://www.youtube.com/embed/${this.state.pub.vid}`} title={this.state.pub.title} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                        </div>
                                        :
                                        null
                                        }
                                    </div>

                                </div>
                            </section>
                        </div>
                    </body>
                    <Rodape promos={this.state.promos} services={this.state.services} />
                    <Lined />
                </div>
            </Animate>
        );
    }
}

export default Pub;