import './Blog.css';
import './font-icons.css';
import Header from '../../components/header/Header';
import PostCard from './card';
import React from 'react';
import axios from 'axios';
import { API_URL, axiosconfig } from '../../core';
import Rodape from '../../components/rodape/Rodape';
import Animate from 'react-smooth';
import banner from '../../images/banner-blog.png';
import { Lined } from '../../components/lined/Lined';

class Blog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pubs: {},
            page: 1,
            promos: [],
            services: []
        };
    }
    componentDidMount() {
        let url = API_URL + '/api/pubs?format=json'
        try {
          axios.get(url, axiosconfig).then(res => { this.setState({ pubs: res.data }); });
        } catch (err) {
            console.error(err);
        }
    
        let url2 = API_URL + '/api/promos?format=json'

        try {
            axios.get(url2, axiosconfig).then(res => { this.setState({ promos: res.data }); });
        } catch (err) {
            console.error(err);
        }
     
        let url3 = API_URL + '/api/services?format=json'
        try {
             axios.get(url3, axiosconfig).then(res => { this.setState({ services: res.data }); });
        } catch (err) {
            console.error(err);
        }
    }
    updatePubs(action) {
        let url = API_URL + `/api/pubs?page=${action === 'next' ? this.state.page + 1 : this.state.page - 1}&format=json`
        try {
            axios.get(url, axiosconfig).then(res => { this.setState({ pubs: res.data, page: action === 'next' ? this.state.page + 1 : this.state.page - 1 }); });
        } catch (err) {
            console.error(err);
        }
      
    }
    render() {
        return (            
            <Animate to="1" from="0" attributeName="opacity">
            <div style={{position: 'relative'}}>
                <Header alwaysBlue={true} services={this.state.services} promos={this.state.promos} />
                <body className="stretched">
                    <div className="clearfix">
                        <section id="page-title" style={{ backgroundImage: `url(${banner})`, paddingTop: '300px' }}>
                            <div className="container clearfix" >
                                <h1 style={{ color: '#ffffff' }}>Blog Delta</h1>
                                <span style={{ color: '#ffffff', fontWeight: 'bold' }}>Confira as últimas Notícias</span>
                            </div>
                        </section>
                        <section id="content">
                            <div className="content-wrap">
                                <div className="container clearfix">
                                    <div className="row col-mb-80">
                                        <div className="postcontent col-lg-12">
                                            <div id="posts" className="row grid-container">
                                                {this.state.pubs.results ? this.state.pubs.results.map((item, i) =>
                                                    <div key={i}>
                                                        <PostCard pub={item} />
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="d-flex justify-content-between mt-5">
                                                {this.state.pubs.previous ? <button className="btn btn-outline-secondary" onClick={() => this.updatePubs('previous')}>&larr; Anterior</button> : <div></div>}
                                                {this.state.pubs.next ? <button className="btn btn-outline-dark" onClick={() => this.updatePubs('next')}>Próxima &rarr;</button> : <div></div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </body>
                <Rodape promos={this.state.promos} services={this.state.services} />
                <Lined />
            </div>
            </Animate>
        );
    }
}

export default Blog;