import React from 'react';
import { Card } from 'react-bootstrap';
import Animate from 'react-smooth';

export default class LocalizadorCard extends React.Component {
    render(){
        return (
            <Animate to="1" from="0" attributeName="opacity">
            <div style={{padding: '0.5rem'}}>
                <Card style={{width: '200px'}}>
                    <Card.Body>
                        <Card.Title>Posto Delta</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">{this.props.fuelStation.city} - {this.props.fuelStation.province}</Card.Subtitle>
                        <Card.Link href={`https://maps.google.com?q=${this.props.fuelStation.latitude}+${this.props.fuelStation.longitude}`} target={'_blank'}>Ver no Maps</Card.Link>
                        <p><Card.Link href={`https://www.waze.com/ul?ll=${this.props.fuelStation.latitude}%2C${this.props.fuelStation.longitude}&navigate=yes&zoom=17`} target={'_blank'}>Ver no Waze</Card.Link></p>
                    </Card.Body>
                </Card>
            </div>
            </Animate>
        );
    }
}
